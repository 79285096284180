.tag__list {
    .tag {
        color: @activeColor;
        border: 2px solid @activeColor;
    }

    button {
        color: #fff;

        &:hover {
            background-color: @activeColor ;
            border-color: #fff;

            .icon {
                color: #fff;
            }
        }

        .icon {
            color: @activeColor;
        }

    }

}