


@selectPadding: @margin;

.select__wrapper {
    .textRegular();
    position: relative;
    margin-bottom: @margin;

    .select--filter {
        display: flex;
        justify-content: space-between;

        .btn {
            flex-shrink: 0;
        }
    }

    button.select {
        width: 100%;
    }

    .tail {
        position: absolute;
        width: 100%;
        max-height: 0px;
        overflow: hidden;
        display: flex;
        flex-direction: column;

        .domain__item,
        .term__item {
            text-align: left;
        }

        .item-label {
            font-weight: @fwSemiBold;
        }
    }
}

.select {
    cursor: default;

    .selected {
        display: flex;
        justify-content: space-between;
        padding: (@selectPadding / 2);
        background-color: @white;
    }
}

.select__wrapper.active {
    background-color: white;
    z-index: 20;

    .tail {
        max-height: 33vh;
        transition: max-height 0.3s;
        overflow: auto;
    }
}

/// STYLE

.select__wrapper.active {
    .select {
        border-bottom: @borderLight;
    }

    .tail {
        background-color: white;
        padding: (@selectPadding / 2);
        // border-bottom: @borderLight;
        border: 0px;
        -webkit-box-shadow: 0px 6px 9px -4px rgba(153, 153, 153, 1);
        -moz-box-shadow: 0px 6px 9px -4px rgba(153, 153, 153, 1);
        box-shadow: 0px 6px 9px -4px rgba(153, 153, 153, 1);
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
    }
}

.select {
    border-bottom: 1px solid transparent;
    background-color: transparent;

    .selected {
        border-bottom: @borderLight;
    }
}

.select {
    .selected:not(.none),
    .tail > button {
        .activeText();
        position: initial;
        width: 100%;
    }

    .selected:not(.none) {
        padding: (@selectPadding / 2) !important; //override .activeText() mixin padding
        border-bottom: @borderLight;
        width: 100%;
        background: #fff;
        z-index: 1;
    }

    .selected:after {
        .fa();
        content: @fa-var-angle-right !important;
        position: relative;
        // right: (@margin / 2 !important;
    }
}

.select__wrapper.active {
    .selected:after {
        content: @fa-var-angle-down !important;
    }
}

.select .item-description {
    .textExtraSmall();
}
