@itemWidth: 2.5rem;


.view {

    .sidebar-right .sidebar-header.legend-header-info H1,
    .sidebar-right .sidebar-header H1 {
        line-height: 100% !important;
        padding-top: 3px;
    }
}

.view .switcher {
    top: -1px;
    width: @itemWidth;

    .switch-item {
        width: @itemWidth;
        height: @itemWidth;
    }

    .switch-item {
        span.picto {
            display: block;
            width: @itemWidth;
            height: @itemWidth;
            color: @textColor;
        }

        &.active span.picto {
            border-color: @activeColor;
            background-color: @pink;

            &:before {
                color: @textColor;
            }
        }

        &:hover span.picto {
            background-color: lighten(@pink, 10%);

            &:before {
                color: @textColor;
            }
        }

        &.active:hover span.picto {
            background-color: @pink;
        }
    }

    .switch-item .picto:before {
        font-size: 1rem;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: @textColor;
    }
}

.view .map-wrapper .map-scale {
    right: 0px;
    left: initial;
    border-right: 0px;
    padding-left: 0.5rem;
}

.view .control-box {
    bottom: calc(~'16px + (@{margin}/2)');
}

.view .vertical-split .map-wrapper .control-box {
    flex-direction: row;
    width: auto;

    >* {
        margin-left: (@margin / 2);
    }
}


.view .map-navigator .map-list .map-tile .map-tile-img {
    border-radius: 0px;
}


.print-block {
    .a3 {
        display: none;
    }
}