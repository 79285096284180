h2.head-title {
    padding: @margin 0;
    border-top: 1px solid @textColor;
    border-bottom: 1px solid @greyDark;
}

/// VARIABLES / MIXINS

// ON HOLD
//
// @bigUnderlineBaseColor : @orange;

// .bigUnderline(@color: @bigUnderlineBaseColor) {
//     border-bottom: 4px solid @color;
// }

@headerSidebarW: 22rem;

.app-name__wrapper {
    .triangle(@cornerTopLeft, @greyExtraLight);
    width: @headerSidebarW;
    padding-bottom: @margin;
    margin-right: @margin;

    h1 {
        // font-size: 4rem;
        // font-weight: 900;
        margin: @margin 0;
    }
}

.app-name__wrapper {
    .absolute(0, initial, initial, 0);
    height: 100%;
    margin-right: @margin;
    z-index: -1;
}

.project-header,
.editor__header,
.review__header,
.query-header {
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.header__content {
    border-top: 1px solid @textColor;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &-top {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .breadcrumb__wrapper {
        margin-top: @margin;
        color: @inactiveColor;

        .breadcrumb {
            .textBig(@textColorLight);
            font-weight: 600;
            display: flex;
            flex-wrap: wrap;

            h2 {
                margin-right: @margin;
            }
        }

        .btn {
            margin-top: @margin;
        }
    }

    .header__tools .btn {
        margin-left: @margin;
    }
}

/// OVERIDES

// client/login

.main.login .login-wrapper {
    padding: @margin 0;
    border-left: 0px;
    border-right: 0px;
    width: 100%;

    .login-widget {
        display: block;
        padding: @margin;
        // background-color: @bodyBgColor;
    }
}

// client/view
.map-and-info .map-wrapper {
    left: 0;
}