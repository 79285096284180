.checkbox {
  cursor: default;
}
.checkbox:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  margin-right: 5px;
}
.checkbox:before.fa-pull-left {
  margin-right: 0.3em;
}
.checkbox:before.fa-pull-right {
  margin-left: 0.3em;
}
.checkbox::before {
  content: "\f0c8";
}
.checkbox:hover:before,
.checkbox.active:before {
  content: "\f14a";
}
.checkbox__item {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 0.99rem;
  color: #0e3b58;
  cursor: default;
  min-height: 2em;
}
.checkbox__label::first-letter {
  text-transform: capitalize;
}
.checkbox__box {
  flex-shrink: 0;
  position: relative;
  width: 1em;
  height: 1em;
  margin-right: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.checkbox__box::after,
.checkbox__box::before {
  content: '';
  width: 1px;
  height: 150%;
}
.checkbox__box::before {
  transform: rotate(45deg);
}
.checkbox__box::after {
  transform: rotate(-45deg);
}
.checkbox__item .checkbox__box {
  background-color: #fff;
  border: 1px solid #df5844;
}
.checkbox__item:hover .checkbox__box {
  border: 1px solid #a32203;
}
.checkbox__item:hover .checkbox__box::after,
.checkbox__item:hover .checkbox__box::before {
  background-color: #a32203;
}
.checkbox__item:active .checkbox__box {
  border: 1px solid #7b3418;
}
.checkbox__item:active .checkbox__box::after,
.checkbox__item:active .checkbox__box::before {
  background-color: #7b3418;
}
.checkbox__item.selected .checkbox__box {
  border: 1px solid #df5844;
}
.checkbox__item.selected .checkbox__box::after,
.checkbox__item.selected .checkbox__box::before {
  background-color: #df5844;
}
.checkbox__item.disabled {
  color: #e1e1e1;
  border: 1px solid #e1e1e1;
}
.checkbox__item.disabled .checkbox__box {
  background-color: #e1e1e1;
}
.checkbox__item.disabled .checkbox__box::after,
.checkbox__item.disabled .checkbox__box::before {
  background-color: #e1e1e1;
}
.checkbox__item.disabled:hover .checkbox__box {
  background-color: #e1e1e1;
}
.checkbox__item.disabled:hover .checkbox__box::after,
.checkbox__item.disabled:hover .checkbox__box::before {
  background-color: #fff;
}