.selected-base-layers,
.available-base-layers {
    margin-top: @margin;
}

.selected-base-layers {
    .base-layer-selected {
        display: flex;
        gap: @margin;
        height: 1.5rem;
        align-items: center;

        .label-wrapper {
            width: 100%;
            padding: 0.5ch;

            .icon {
                width: 1rem;
                margin-right: 0.5ch;
            }
            label {
                font-weight: @fwSemiBold;
            }
        }

        .baselayer-selected-actions {
            flex-shrink: 0;
            margin-left: auto;
            display: none;
        }

        &:hover {
            .label-wrapper {
                background-color: @bgActiveColor;
                outline: 0.15rem solid @bgActiveColor;
            }

            .baselayer-selected-actions {
                display: block;
            }
        }
    }
}

details.webservice {
    // margin-left: calc(~"@{margin} + 0.5ch")
    margin-left: calc(~'1rem + 1ch');

    &[open] {
        margin-bottom: @margin;
    }

    summary {
        font-weight: @fwSemiBold;
        margin-bottom: @margin*0.5;
        width: fit-content;

        &:hover .webservice-name {
            text-decoration: underline;
        }
    }
    .base-layer-item {
        cursor: default;
        display: flex;
        align-items: center;
        width: fit-content;
        padding: 0.5ch;

        .icon {
            color: transparent;
            margin-right: 0.5ch;
        }

        &:hover {
            background-color: @bgActiveColor;
            outline: 0.15rem solid @bgActiveColor;

            .icon {
                color: @activeColor;
            }

            &.disabled {
                background-color: transparent;
                outline: initial;
            }
        }

        &.disabled {
            color: @disabledColor;
            .icon {
                color: transparent !important;
            }
        }
    }
}
