// .sidebar-header.feature-header {
//     .layer-name {
//         margin: @margin 0;
//         margin-top: (@margin / 2);
//         font-size: 0.8rem;
//     }
// }

.feature-info-page {
    overflow-y: auto;

    // .sidebar-header {
    //     padding: 0 @margin;
    // }
    // H1 {
    //     padding-bottom: @margin;
    // }

    H2 {
        margin-bottom: @margin*0.5;
    }

    .tip-desktop {
        margin-bottom: @margin*0.5;
    }

    .group {
        border-top: 1px dotted @borderLightColor;

        &:first-child .layer-name {
            margin-top: @margin;
        }

        .layer-name {
            padding: @margin;
            background-color: @bgLightColor;
            // margin-top: @margin*2;
            // margin-bottom: @margin*0.5;
            font-style: italic;
            font-size: 120%;
            text-align: center;
        }

        .actions {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        details {
            border-top: 1px dotted @borderLightColor;

            &:last-child {
                border-bottom: 1px dotted @borderLightColor;
            }

            summary {
                margin: 0;
                padding: @margin*0.3;
                display: flex;
                align-items: center;

                .feature-summary {
                    width: 100%;
                    display: flex;
                    align-items: center;
                }

                .actions {
                    display: none;
                    margin-left: auto;
                    align-items: center;
                }

                .feature-title {
                    margin: 0;
                    margin-left: 1ch;
                }
            }

            &:hover {
                summary {
                    background-color: @bgLightColor;

                    .actions {
                        display: flex;
                    }
                }
            }
        }

        .feature-info-wrapper {
            margin: @margin;
            margin-top: 0;
        }
    }
}

.feature-view {
    &.config {
        ////// STRUCTURE  //////

        .feature-view-header {
            .widgetBackground;
            .flex(baseline, space-between, nowrap);
            font-size: 80%;
            margin-bottom: @margin;

            .layer-name {
                text-transform: uppercase;
            }

            .btn-close:before {
                content: @fa-var-times;
            }
        }

        .feature-field {
            .word-wrap;
            margin-bottom: @small-margin;
        }

        .feature-field-label {
            .labelStyle;
        }

        ////// STYLES //////

        .type- {
            &string {
            }

            &number {
                font-family: monospace;
            }

            &boolean {
                // color:blue;
            }

            &url {
            }

            &image {
                width: 100%;
                height: auto;
                margin: @margin 0;
            }
        }

        .level- {
            &normal {
                margin-bottom: @small-margin;
            }

            &title {
                font-weight: @fwLight !important;
                font-size: 250%;
                line-height: 90%;
                letter-spacing: -1.5px;
                margin-bottom: @margin;
            }

            &subtitle {
                font-size: 150%;
                font-weight: @fwBold;
                margin-bottom: @margin;
            }
        }

        .style- {
            &normal {
            }

            &bold {
                font-weight: @fwBold;
            }

            &italic {
                font-style: italic;
            }

            &bold-italic {
                font-style: italic;
                font-weight: @fwBold;
            }
        }
    }
}

.map-and-table-and-feature .feature-view {
    // padding-left:@margin;
    .feature-row {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        border-top: 1px solid @borderLightColor;

        > div {
            padding: @small-margin 0;
        }

        > div:first-child {
            flex: 1;
        }

        > div:last-child {
            flex: 2;
        }
    }
}
