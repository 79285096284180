// header logos

.header .brand-logo .brand-name {
    background-image: url('assets/imgs/logo.svg');
    width: 100px;
    height: 20px;
}

.app-infos {
    line-height: 120%;
}


.map-list .map-tile {
    color: @textColor;

    .map-status {
        border-color: @textColor;
    }

    .map-status.published .icon {
        color: @textColor;
    }

}