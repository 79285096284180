.helptext {
    margin: @margin 0;
    line-height: 120%;

    p {
        margin-top: (@margin / 2);

        &:first-child {
            margin: 0px;
        }
    }
}
