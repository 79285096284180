// style

H1.pitch {
    line-height: 1;
    font-size: 2rem;

    .md p:last-child {
        margin-bottom: 0px;
    }
}

.view-inner .main {
    overflow: hidden;
}

.map-navigator {
    display: flex;
    overflow: hidden;
    height: 100%;
    width: 100%;

    h2 {
        margin-bottom: @margin;
    }

    h3 {
        .word-wrap;
        color: @textColor;
        margin: @margin 0;
    }

    .sidebar {
        display: flex;
        flex-direction: column;
        width: @atlasSidebarW;
        flex-shrink: 0;
        background-color: @bgLightColor;
        padding: @margin;
        margin-right: @margin;
    }

    .sidebar-footer {
        margin-top: auto;
        padding-top: @margin;
    }

    .content {
        height: 100%;
        width: 100%;
        overflow: auto;
        padding-right: @margin;
    }
}

.map-navigator .sidebar {

    .input-wrapper {
        font-size: 1.5rem;
        display: flex;
        background-color: @white;
        margin: @margin 0;

        input {
            width: calc(~'100% - @{margin}');
            border: 0px;
            background-color: transparent;

            &::placeholder {
                color: rgb(90, 90, 90);
            }
        }

        .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 2em;
        }
    }

    .category__wrapper {
        .checkbox__wrapper {
            label {
                flex-direction: row-reverse;
                justify-content: flex-end;
                font-size: 1.2rem;
                font-weight: @fwSemiBold;

                input[type='checkbox'] {
                    margin-left: 0;
                    margin-right: 1ch;
                    padding: 0.2rem;
                    padding-left: 0;
                }
            }
        }
    }

    .sidebar-switch {
        margin-top: @margin;
    }
}

.map-navigator .app-list__wrapper {
    width: 100%;

    .list {
        display: flex;
        gap: @margin;
        padding-bottom: @margin;
        margin-bottom: @margin;
        width: 100%;
        overflow-x: auto;

        .app-item {
            flex-basis: 100%;
            min-width: 180px;
            max-width: 300px;
            font: inherit;
            text-align: left;
            display: flex;
            flex-direction: column;

            &:hover,
            &:focus-visible {
                outline: none;

                H3 {
                    text-decoration: underline;
                }
            }
        }

        .app-img {
            position: relative;
            width: 100%;

            &:after {
                content: "";
                display: block;
                padding-bottom: 66%;
            }

            img {
                position: absolute;
                height: 100%;
                // object-fit: contain;
                background-color: transparent;
            }
        }

        h3 {
            font-size: 1.7rem;
            color: @activeColor;
            margin-bottom: 0px;
            margin-top: @margin * 0.5;

            .picto {
                .fa();
                margin-right: 1ch;
                font-family: 'ForkAwesome'; //FIXME
                font-weight: initial;
            }
        }
    }
}

.map-navigator .map-list {
    display: flex;
    flex-wrap: wrap;
    gap: @margin;
}