// text color is defined in ./colors.less
// default textcolor is @textColor.

//fonts
@gui-font: 'u001';
@gui-font-size: 0.9rem;
@cond-gui-font-size: 0.8rem; // JUNK ?? To be sorted

/******** FONT WEIGHTS ********/
// we could need some fallback
// when a font-family has fewer
// weight options

@fwBlack: 900;
@fwExtraBold: 700;
@fwBold: 600;
@fwSemiBold: 500;
@fwRegular: 400;
@fwThin: 300;
@fwLight: 200;
@fwExtraLight: 100;

/******** FONT SIZES ********/

.textExtraLarge(@color: @textColor) {
    font-weight: @fwBlack; //black
    font-size: @gui-font-size * 2;
    color: @color;
}

.textBig(@color: @textColor) {
    font-weight: @fwBlack; //black
    font-size: @gui-font-size * 1.4;
    color: @color;
}

.textRegular(@color: @textColor) {
    font-weight: @fwSemiBold; // semi-bold
    font-size: @gui-font-size * 1.1;
    color: @color;
}

.textSmall(@color: @textColor) {
    font-weight: @fwRegular; // regular
    font-size: @gui-font-size;
    color: @color;
}

.textExtraSmall(@color: @textColor) {
    font-weight: @fwRegular; // regular
    font-size: @gui-font-size * 0.8;
    color: @color;
}

/******** INTERACTIVE TEXT ********/

// decoration

.checkIcon(@color: @activeColor) {
    .fa();
    content: @fa-var-check;
    display: block;
    // position: absolute;
    // top: 0.2em;
    // top: 0;
    // right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: @color;
    width: 1em;
    // height: 1em;
    height: 100%;
}

// Base

.activeTextBase() {
    position: relative;
    color: @textColor;
    border-bottom: 1px solid transparent;
    cursor: default;
    line-height: 110%;
    padding: 0.2em 0;
    display: flex;
    justify-content: space-between;

    &::after {
        .checkIcon(transparent);
    }
}

// Hover

.activeTextHover(@Color: @activeColor) {
    color: @Color;
    border-color: @Color;

    &::after {
        .checkIcon(@Color);
    }
}

// Pressed - OnClick

.activeTextPressed(@Color: @activeColorPressed) {
    color: @Color;
    border-color: @Color;

    &::after {
        .checkIcon(@Color);
    }
}

// current - selected

.activeTextCurrent(@Color: @activeColor) {
    color: @Color;

    // border-color: transparent;
    &::after {
        .checkIcon(@Color);
    }
}

// enabled

.activeTextEnabled(@Color: @activeColor) {
    color: @Color;

    // border-color: transparent;
    &::after {
        .checkIcon(@Color);
    }
}

// general mixin

.activeText() {
    .activeTextBase();

    &.enabled {
        .activeTextEnabled();
    }

    &.selected {
        .activeTextCurrent();
    }

    &:hover {
        .activeTextHover();
    }

    &:focus,
    &:active {
        .activeTextPressed();
    }
}

/******** INPUT ********/

.textInput(@color: @textColor) {
    font-weight: 400; // regular
    font-size: @gui-font-size * 1.2;
    color: @color;
}

/******** Junk - TO BE SORTED ********/

@content-font: @gui-font; // ???
@content-font-size: @gui-font-size; // ???

// UTILS - TEXT & TYPO - to be sorted - potential junk here..

.word-wrap() {
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}

.link {
    .transition;
    cursor: pointer;

    &:hover {
        color: @linkColorHover;
    }
}

.labelStyle {
    display: block;
    font-weight: @fwBold;
    margin-bottom: @small-margin;
    text-decoration: underline;
}

.text-shadow(@color) {
    text-shadow: -1px -1px 0 @color, 1px -1px 0 @color, -1px 1px 0 @color,
        1px 1px 0 @color;
}