.sidebar__wrapper {
    .styles-wrapper {


        .map-legend__wrapper {
            clear: both;
            border-top: 2px solid @borderDarkColor;
            margin-top: @margin*0.5;
        }


        .map-legend__wrapper,
        .related-maps,
        .map-attached-files {
            border-bottom: 2px solid @borderDarkColor;
        }


        .legend-label {
            margin-top: @margin*1.5;
            margin-bottom: @margin*0.75;
            font-weight: @fwSemiBold;
        }

        .legend-item {
            width: 100%;
            display: flex;
            align-items: center;
            margin-bottom: @small-margin;
            min-height: 20px;

            &.polygon {
                .item-style>div {
                    height: 16px !important;
                }
            }

            &.point {
                .item-style {
                    max-width: 32px;
                    max-height: 32px;
                    overflow: hidden
                }
            }

            .item-style {
                .legendItemPosition;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    height: auto;
                }
            }

            .item-label {
                min-height: 20px;
                display: flex;
                align-items: center;
            }
        }

        .opacity-picker .input-alpha-label {
            display: flex;
            padding-left: calc(~"@{legend-itemW} + @{margin}");
            font-size: 0.75rem;
            margin-bottom: @margin;
            margin-top: -5px;

            .label {
                flex-shrink: 0;
            }

            input {
                margin-left: @margin;
                width: 50%;
            }
        }
    }

    .legend-group.named {
        width: 100%;
        margin-bottom: @margin;

        .legend-group-title {
            font-weight: 600;
            margin: @margin auto @small-margin auto;
            
            .visible:before {
                .far();
                content: @fa-var-eye;
            }
        }

        .legend-group-items {
            border-left: 1px solid @borderLightColor;
            padding-left: @margin;
            margin-left: @margin;
            
        }
        .not-visible {
            color: @inactiveColor;
            .interactive;
    
            &:before {
                .far();
                content: @fa-var-eye-slash;
            }
        }
    
        .visible:before,
        .not-visible:before {
            padding-right: @small-margin * 2;
        }
    }

    .wms-legend-wrapper {
        width: 100%;
        margin-top: @margin;
        padding: @margin 0;
        border-top: 2px solid @borderDarkColor;
        overflow-x: auto;
        overflow-y: hidden;

        .wms-legend-switch {
            .interactive;
            // font-weight: 100;
            display: flex;

            &::before {
                .fa();
                .legendItemPosition;
                justify-content: center;
            }

            &.opened::before {
                content: @fa-var-chevron-down;
            }

            &.closed::before {
                content: @fa-var-chevron-right;
            }
        }

        .wms-legend-item {
            margin-left: @legend-itemW + @margin;
            margin-top: @small-margin;
        }
    }
}