.geocoder-wrapper {
    .absolute((@margin / 2), initial, initial, (@margin / 2));
    z-index: 2;
    display: flex;
    align-items: center;

    .mono {
        // height: calc(~"2em + 4px");
        height: 2rem;
        width: 15ch;
        width: fit-content;
        background-color: @bodyBgColor ;
        color: @activeColor ;
        padding: 0 @margin;
        border: 2px solid @activeColor;
        border-right: 0px;
        border-bottom-left-radius: 1em;
        border-top-left-radius: 1em;
        display: flex;
        align-items: center;

        // &:after {
        //     .fas();
        //     content: @fa-var-angle-down;
        //     width: 1.5rem;
        //     margin-left: @margin;
        // }
    }

    .select {
        margin-bottom: 0px;

        // * {
        //     border: 0px !important;
        //     font-size: 1rem !important;
        // }
        select {
            height: 2rem;
            width: 15ch;
            width: fit-content;
            background-color: @bodyBgColor ;
            color: @activeColor ;
            padding: 0;
            padding-left: @margin  !important;
            border: 2px solid @activeColor;
            border-right: 0px;
            border-bottom-left-radius: 1em;
            border-top-left-radius: 1em;

            option {
                color: @textColor;
            }

            &:focus-visible,
            &:hover {
                background-color: @activeColor;
                color: @white;
                outline: 0px;
            }
        }
    }

    .search {
        display: flex;
        width: 40ch;

        input[type='text'] {
            width: 100%;
            border: 2px solid @activeColor;
            border-right: 0px;
            height: 2rem;
            border-radius: initial;

            &:focus {
                outline: none;
            }
        }

        .btn {
            border-bottom-left-radius: 0px !important;
            border-top-left-radius: 0px !important;
            border-left: 0px;
        }

        input,
        .btn {
            height: 2rem !important;
            box-sizing: border-box;
        }

        .coord-actions {
            display: flex;
            border-left: 2px solid @activeColor;

            .btn {
                border-radius: 0px;

                &:last-child {
                    border-top-right-radius: 2rem;
                    border-bottom-right-radius: 2rem;
                }
            }
        }
    }

    .search-results {
        width: 40ch;
        position: absolute;
        top: 1rem;
        z-index: -1;
        background-color: @bodyBgColor;
        padding: (@margin / 2);
        padding-top: 1.5rem;
        border: 2px solid @activeColor;
        border-bottom-left-radius: 1em;
        border-bottom-right-radius: 1em;

        .result {
            cursor: default;
            margin-bottom: (@margin / 4);
            display: flex;

            // button {

            //     &:before {
            //         .far();
            //         flex-shrink: 0;
            //         content: @fa-var-circle;
            //         color: @disabledColor;
            //         width: 1rem;
            //         height: 1rem;
            //         margin-right: (@margin / 2);
            //     }

            //     &:focus-visible {
            //         outline: 0px;
            //         &::before {
            //             .fas();
            //             color: @activeColor;
            //             content: @fa-var-check;
            //         }
            //     }
            // }
            // &:hover button:before {
            //     .fas();
            //     color: @activeColor;
            //     content: @fa-var-check;
            // }
        }

        .result-select {
            margin-bottom: (@margin / 4);
            width: 100%;
            display: flex;
            align-items: center;

            .result-label {
                flex-shrink: 1;
            }

            // .action-select,
            .result-actions {
                margin-left: auto;
                flex-shrink: 0;
                display: flex;
            }
        }

        .result-select.simple {
            button {
                width: 100%;
                display: flex;
                gap: @margin;
                justify-content: space-between;
                align-items: center;
                text-align: left;

                .icon {
                    flex-shrink: 0;
                    color: @activeColor;
                    width: 2rem;
                    height: 2rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 2px solid transparent;
                    border-radius: 100%;
                }

                &:hover {
                    .label {
                        text-decoration: underline;
                    }

                    .icon {
                        border-color: @activeColor;
                    }
                }
            }
        }
    }
}