@btnFontSize: 0.8rem;
@innerPadding: (@btnFontSize / 2);
@borderRadius: 0;

// RESET BUTTON BASE STYLE

button {
    .focusVisible();
    border: 0px;
    background-color: transparent;
    font: 400 @btnFontSize @gui-font;
    padding: 0px;
}

// STRUCTURE

.btn {
    height: calc(~'2em + @{innerPadding}');
    display: inline-flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: flex-end;
    padding: 0 1em;
    box-sizing: border-box;
    flex-shrink: 0;
    -ms-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    user-select: none;

    &.label-and-icon .icon {
        padding-right: 0.5ch;
    }

    &.icon-only {
        width: calc(~'2em + @{innerPadding}');
        padding: 0;

        .icon {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}



// .btn.btn-3 {
//     padding: 0;
// }

// STYLE GENERAL

.label-and-icon,
.icon-only {
    .icon {
        .fa();
    }
}

.btn {
    .transition;
    cursor: default;
    letter-spacing: 0.1ch;
    font-family: 'u001con';
    text-transform: uppercase;
    font-weight: 400;
    font-size: @btnFontSize;
    border-radius: @borderRadius;

    * {
        .transition;
    }
}

@btnInactiveColor: lighten(@greyMedium, 5%);

// STYLE LEVEL 1 - Big Buttons

@btn1Color: #fff;
@btn1InnerColor: @purple;
@btn1BorderColor: @btn1InnerColor;

@btn1ColorHover: @btn1Color;
@btn1InnerColorHover: @btn1InnerColor - 30%;
@btn1BorderColorHover: @btn1InnerColorHover - 30%;

@btn1ColorFocus: @btn1Color;
@btn1InnerColorFocus: @btn1InnerColor - 60%;
@btn1BorderColorFocus: @btn1InnerColorFocus;

.btn-1 {
    background-color: @btn1InnerColor;
    color: @btn1Color;
    border: 1px solid @btn1BorderColor;

    &:hover {
        color: @btn1ColorHover;
        background-color: @btn1InnerColorHover;
        border-color: @btn1BorderColorHover;
    }

    &:focus,
    &:active {
        color: @btn1ColorFocus;
        background-color: @btn1InnerColorFocus;
        border-color: @btn1BorderColorFocus;
    }

    &.inactive,
    &.disabled {
        color: @btn1Color;
        background-color: @btnInactiveColor;
        border-color: @btnInactiveColor;
    }

    &.icon-only {
        border: 0px;
        border-radius: 100%;
    }
}

// STYLE LEVEL 2 - Average buttons
@btn2Color: @purple;
@btn2InnerColor: @bodyBgColor;
@btn2BorderColor: @purple;

@btn2ColorHover: @btn2Color;
@btn2InnerColorHover: @btn2InnerColor - 15%;
@btn2BorderColorHover: @btn2BorderColor;

@btn2ColorFocus: @btn2Color;
@btn2InnerColorFocus: @btn2InnerColor - 30%;
@btn2BorderColorFocus: @btn2BorderColor;

.btn-2 {
    background-color: @btn2InnerColor;
    border: 1px solid @btn2BorderColor;
    color: @btn2Color;

    &:hover {
        color: @btn2ColorHover;
        background-color: @btn2InnerColorHover;
        border-color: @btn2BorderColorHover;
    }

    &:focus,
    &:active {
        color: @btn2ColorFocus;
        background-color: @btn2InnerColorFocus;
        border-color: @btn2BorderColorFocus;
    }

    &.inactive,
    &.disabled {
        color: @btnInactiveColor;
        background-color: transparent;
        border-color: @btnInactiveColor;
    }

    &.icon-only {
        border: 1px solid @btn2BorderColor;
        border-radius: 100%;
    }
}

// STYLE LEVEL 3 - DENSE INTERFACE

@btn3Color: @purple;
@btn3InnerColor: transparent;
@btn3BorderColor: transparent;

@btn3ColorHover: @purple + 15%;
@btn3InnerColorHover: transparent;
@btn3BorderColorHover: transparent;

@btn3ColorFocus: @purple + 30%;
@btn3InnerColorFocus: transparent;
@btn3BorderColorFocus: transparent;

.btn-3 {
    background-color: @btn3InnerColor;
    border: 1px solid @btn3BorderColor;
    color: @btn3Color;

    &:hover {
        color: @btn3ColorHover;
        background-color: @btn3InnerColorHover;
        border-color: @btn3BorderColorHover;
        border-bottom-color: @btn3ColorHover;
    }

    &:focus,
    &:active {
        color: @btn3ColorFocus;
        background-color: @btn3InnerColorFocus;
        border-color: @btn3BorderColorFocus;
        border-bottom-color: @btn3ColorFocus;
    }

    &.inactive,
    &.disabled {
        color: @btnInactiveColor;
        background-color: transparent;
        border-color: transparent;
    }

    &.icon-only {
        border: 0px;
        border-radius: 100%;

        &:hover,
        &:focus,
        &:active {
            border: 1px solid @btn2BorderColor;
        }

        &.inactive,
        &.disabled {

            &:hover,
            &:focus,
            &:active {
                border: 1px solid transparent;
            }
        }
    }
}

// TRANSLATE/D BUTTON
.btn-translate {
    color: @greyDark;
    margin: 0 !important; //reset
    margin-right: -1em !important;
    margin-top: -1em !important;

    // .icon {
    //     color: inherit;
    // }

    &.not-translated {
        color: @activeColor;
    }
}

.translatable.input .btn-translate {
    height: auto;
    background-color: lighten(@pinkLight, 5%) !important;
    border-right: 0px !important;
}

// REMOVE - CONFIRMATION WIDGET

.btn.btn-remove {
    // font-size: 0.65rem;
    // font-weight: 400;

    &:hover {
        border: 1px solid @yellow;
        background: @yellow;
        color: @activeColor;
    }

    &:active {}
}

.remove-confirm {
    font-size: 1rem;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    background: @white;
    padding: (@margin / 2);
    background: rgba(255, 255, 255, 0.2);

    .remove-confirm-box {
        -webkit-box-shadow: 0px 0px 30px 0px rgba(100, 100, 100, 0.5);
        -moz-box-shadow: 0px 0px 30px 0px rgba(100, 100, 100, 0.5);
        box-shadow: 0px 0px 30px 0px rgba(100, 100, 100, 0.5);
        background: @white;
        width: 33vw;
        height: 33vh;
        min-width: 350px;
        min-height: 250px;
        padding: @margin * 2;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .remove-confirm-btns {
            display: flex;
            justify-content: space-around;
        }
    }
}

// NOTES FOR ANIMATIONS
// button transition :
// :active trigger event on click PRESS
// transition trigger event on clic RELEASE