input,
input[type='email'],
input[type='password'],
input[type='search'],
input[type='url'],
input[type='text'],
input[type='number'],
textarea {
    border-left: 0px;
    border-right: 0px;
    background-color: lighten(@pinkLight, 5%);
}

input[type='checkbox'] {
    border: 0px;
    background-color: transparent;

}