@atlasSidebarW: 27rem;

.table-tools {
    display: flex;
    align-items: center;

    .table-row-count {
        margin-left: @margin *0.5;
    }
}


.vertical-split {
    .infinite-table {
        margin-left: @margin;

        @media @sdi-small {
            margin-left: 0;
        }
    }

    .control-box {
        width: 2rem;
        right: calc(~"3rem + @{margin} / 2");

        @media @sdi-small {
            right: @margin*0.5;
        }
    }
}