.app-announcement {
    padding: @margin;
    background-color: @bgActiveColor;
    display: flex;
    gap: @margin;
    margin-bottom: @margin*0.5;

    .message {
        position: relative;
        background-color: @bodyBgColor;
        padding: @margin*0.5;
        border-radius: 0.5rem;

        &:before {
            content: ' ';
            width: 1rem;
            height: 1rem;
            background-image: url("../assets/imgs/message.svg");
            background-repeat: no-repeat;
            background-position: bottom left;
            position: absolute;
            z-index: 1;
            top: -0.95rem;
            left: 2rem;
        }
    }

    button {
        // margin-left: auto;
        color: @bodyBgColor;
    }
}