.geocoder-wrapper {
    .mono  {
        border-bottom-left-radius: @borderRadius;
        border-top-left-radius: @borderRadius;
    }

    .select {
        select {
            border-bottom-left-radius: @borderRadius;
            border-top-left-radius: @borderRadius;
        }
    }

    .search {
        .btn {
            border-radius: @borderRadius;
            border-bottom-left-radius: 0px !important;
            border-top-left-radius: 0px !important;
            border-left: 2px solid @activeColor;
        }
    }

    .search-results {
        border-bottom-left-radius: @borderRadius;
        border-bottom-right-radius: @borderRadius;
    }
}
