.embed {

    .brand-logo .brand-name {
        height: 13px;
        width: 68px;
        background-image: url("../../assets/imgs/logo.svg");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: bottom left;
        margin-right: 5px;
    }

    .legend .link {
        display: none !important;
    }
}