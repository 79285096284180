@fontFamilyName1: 'u001';
@fontFamilyDir1: '../assets/fonts/u001';
@fontFamilyPathBaseName1: 'u001';

// BLACK - 900

// @font-face {
//     font-family: '@{fontFamilyName1}';
//     src: url('@{fontFamilyDir1}/@{fontFamilyPathBaseName1}-black.woff') format('woff') ;
//     font-weight: 900;
//     font-style: normal;
// }

// @font-face {
//     font-family: '@{fontFamilyName1}';
//     src: url('@{fontFamilyDir1}/@{fontFamilyPathBaseName1}-blackitalic.woff') format('woff') ;
//     font-weight: 900;
//     font-style: italic;
// }

// EXTRA-BOLD -700

// @font-face {
//     font-family: '@{fontFamilyName1}';
//     src: url('@{fontFamilyDir1}/@{fontFamilyPathBaseName1}-extrabold.woff') format('woff') ;
//     font-weight: 700;
//     font-style: normal;
// }

// @font-face {
//     font-family: '@{fontFamilyName1}';
//     src: url('@{fontFamilyDir1}/@{fontFamilyPathBaseName1}-extrabolditalic.woff') format('woff') ;
//     font-weight: 700;
//     font-style: italic;
// }

// BOLD - 600

@font-face {
    font-family: '@{fontFamilyName1}';
    src: url('@{fontFamilyDir1}/@{fontFamilyPathBaseName1}-bold.woff')
        format('woff') ;
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: '@{fontFamilyName1}';
    src: url('@{fontFamilyDir1}/@{fontFamilyPathBaseName1}-bolditalic.woff')
        format('woff') ;
    font-weight: 600;
    font-style: italic;
}

// SEMI-BOLD - 500

// @font-face {
//     font-family: '@{fontFamilyName1}';
//     src: url('@{fontFamilyDir1}/@{fontFamilyPathBaseName1}-semibold.woff') format('woff') ;
//     font-weight: 500;
//     font-style: normal;
// }

// @font-face {
//     font-family: '@{fontFamilyName1}';
//     src: url('@{fontFamilyDir1}/@{fontFamilyPathBaseName1}-semibolditalic.woff') format('woff') ;
//     font-weight: 500;
//     font-style: italic;
// }

// REGULAR - 400 - normal

@font-face {
    font-family: '@{fontFamilyName1}';
    src: url('@{fontFamilyDir1}/@{fontFamilyPathBaseName1}-regular.woff')
        format('woff') ;
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: '@{fontFamilyName1}';
    src: url('@{fontFamilyDir1}/@{fontFamilyPathBaseName1}-italic.woff')
        format('woff') ;
    font-weight: 400;
    font-style: italic;
}

// THIN - 300
// @font-face {
//     font-family: '@{fontFamilyName1}';
//     src: url('@{fontFamilyDir1}/@{fontFamilyPathBaseName1}-thin.woff') format('woff') ;
//     font-weight: 300;
//     font-style: normal;
// }

// @font-face {
//     font-family: '@{fontFamilyName1}';
//     src: url('@{fontFamilyDir1}/@{fontFamilyPathBaseName1}-thinitalic.woff') format('woff') ;
//     font-weight: 300;
//     font-style: italic;
// }

// LIGHT - 200

// @font-face {
//     font-family: '@{fontFamilyName1}';
//     src: url('@{fontFamilyDir1}/@{fontFamilyPathBaseName1}-light.woff') format('woff') ;
//     font-weight: 200;
//     font-style: normal;
// }

// @font-face {
//     font-family: '@{fontFamilyName1}';
//     src: url('@{fontFamilyDir1}/@{fontFamilyPathBaseName1}-lightitalic.woff') format('woff') ;
//     font-weight: 200;
//     font-style: italic;
// }

// EXTRA-LIGHT - 100

// @font-face {
//     font-family: '@{fontFamilyName1}';
//     src: url('@{fontFamilyDir1}/@{fontFamilyPathBaseName1}-extralight.woff') format('woff') ;
//     font-weight: 100;
//     font-style: normal;
// }

// @font-face {
//     font-family: '@{fontFamilyName1}';
//     src: url('@{fontFamilyDir1}/@{fontFamilyPathBaseName1}-extralightitalic.woff') format('woff') ;
//     font-weight: 100;
//     font-style: italic;
// }
